import Swal from "sweetalert2";
import qq88 from "./assets/images/QQ88.png";
import img_button from "./assets/images/button.png";
import back_button from "./assets/images/closebutton.png";

import title from "./assets/images/title.png";
import "./assets/style/reset.css";
import { useCookies } from "react-cookie";

function App() {
  const [cookies, setCookie] = useCookies(["sended"]);

  function Submit() {
    let formDatab = new FormData();
    const name = document.getElementById("name").value;
    const title = document.getElementById("title").value;
    const content = document.getElementById("content").value;
    if (cookies?.sended === 1) {
      return Swal.fire({
        icon: "warning",
        title: "Góp ý bị trùng lặp!",
        html: "<p>Cảm ơn quý khách đã đóng góp ý kiến. </p><p>QQ88 kính chúc quý khách có những trải nghiệm tuyệt vời khi tham gia giải trí.</p>",
      });
    }
    if (name !== "" && title !== "" && content !== "") {
      Swal.fire({
        title: "Đang gửi thông tin!",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      formDatab.append("name", name);
      formDatab.append("title", title);
      formDatab.append("content", content);
      fetch("https://script.google.com/macros/s/AKfycbzpk7yDgJeH_2EzoJTvbY9uuJYSKX1hxOrSvFXZEnOQurXTe-rHPPr0ijetMKMpcVuQDA/exec", {
        method: "POST",
        body: formDatab,
      })
        .then((res) => res.text())
        .then((data) => {
          console.log("sent data " + data);
          if (data === "success") {
            setCookie("sended", "1", {
              path: "/",
              maxAge: 3600,
            });
            Swal.fire({
              icon: "success",
              title: "Gửi thư góp ý thành công!",
              html: "<p>Cảm ơn quý khách đã đóng góp ý kiến. </p><p>QQ88 kính chúc quý khách có những trải nghiệm tuyệt vời khi tham gia giải trí.</p>",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Lỗi trong quá trình gửi thư góp ý!",
              footer: '<a href="#">Liên hệ admin ... </a>',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Lỗi trong quá trình gửi thư góp ý!",
            footer: '<a href="#">Liên hệ admin ... </a>',
          });
        });
    } else {
      Swal.fire({
        icon: "info",
        title: "Hãy điền đầy đủ thông tin!",
      });
    }
  }
  return (
    <div className="main">
      <div className="btn-back">
        <a href="https://qq88.io/trangchu" className="btn-back-text">
          <img src={back_button} alt="" />
        </a>
      </div>
      <div className="header">
        <img src={qq88} alt="" onClick={() => window.location.assign("/")} />
      </div>
      <div className="body">
        <div className="body-text">
          <div className="body-title">
            <img src={title} alt="" />
          </div>
          <div className="body-box">
            <label for="name" className="label">
              TÊN ĐĂNG NHẬP
            </label>
            <div className="box-input">
              <input name="name" id="name" type="text" className="form-control" autoComplete="false" placeholder="Nhập tên đăng nhập" />
            </div>
          </div>
          <div className="body-box">
            <label for="name" className="label">
              TIÊU ĐỀ
            </label>
            <div className="box-input">
              <input name="title" id="title" type="text" className="form-control" autoComplete="false" placeholder="Nhập tiêu đề góp ý" />
            </div>
          </div>
          <div className="body-box">
            <label for="name" className="label">
              NỘI DUNG GÓP Ý
            </label>
            <div className="box-input">
              <textarea name="content" id="content" type="text" className="textarea" autoComplete="false" placeholder="Nhập nội dung góp ý" />
            </div>
          </div>
          <div className="button">
            <button className="image-button" onClick={Submit}>
              <img src={img_button} alt="button" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
